import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { LumiDB } from "@lumidb/lumidb";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";

export function SelectTableDialog(props: {
    lumidb: LumiDB;
    onTableSelected: (tableName: string) => void;
    onChangeAPIKey: () => void;
    onClose: () => void;
}) {
    const [tables, setTables] = useState<{ tableName: string; tableCRS: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(async () => {
            setIsLoading(true);
            try {
                const tables = await props.lumidb.listTables();
                setTables(tables);
            } finally {
                setIsLoading(false);
            }
        }, 0);
        return () => clearTimeout(timer);
    }, [props.lumidb]);

    return (
        <Dialog
            modal={true}
            open={true}
            defaultOpen={true}
            onOpenChange={(open) => {
                if (!open) {
                    props.onClose();
                }
            }}
        >
            <DialogContent hideCloseButton={true}>
                <DialogHeader>
                    <DialogTitle>Select LumiDB table</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <div className="flex flex-col gap-2">
                    {isLoading && <div>Loading tables...</div>}

                    {!isLoading && tables.length === 0 && <div className="">No tables available.</div>}

                    {!isLoading &&
                        tables.map((table) => (
                            <div
                                key={table.tableName}
                                onClick={() => props.onTableSelected(table.tableName)}
                                className="flex cursor-pointer items-center justify-between rounded-md border border-gray-300 p-2 shadow-md hover:bg-gray-200"
                            >
                                <div className="font-bold">{table.tableName}</div>
                                <div className="text-sm text-gray-500">{table.tableCRS}</div>
                            </div>
                        ))}

                    <div className="flex gap-2 self-end">
                        <Button variant="outline" onClick={props.onChangeAPIKey}>
                            Change API Key
                        </Button>
                        <Button variant="outline" onClick={props.onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
