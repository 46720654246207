import { LumiDB, SourceFileMetadata } from "@lumidb/lumidb";
import {
    Box3,
    BoxGeometry,
    DepthTexture,
    EdgesGeometry,
    EventDispatcher,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    OrthographicCamera,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    UnsignedIntType,
    Vector2,
    Vector3,
    WebGLRenderer,
    WebGLRenderTarget,
} from "three";
import { MapControls } from "three/addons/controls/MapControls.js";
import { EDLMaterial } from "./edl-material";
import { DEFAULT_POINT_MATERIAL, PointChunk } from "./point-chunk";
import { ColorMode } from "./point-material";

const CAM_NEAR = 0.5;
const CAM_FAR = 100_000.0;

export type FetchInfo = {
    pointCount: number;
    elapsed: number;
    queryPolygon: number[][][];
    queryArea: number;
    byteSize: number;
    seenIndices: number[];
};

interface FetchPointsOptions {
    lumidb: LumiDB;
    tableName: string;
    queryPolygon: number[][][];
    metaFilter: number[] | null;
    classificationMask: number;
    pointLimit: number;
    maxDensity: number | null;
    why: string;
}

export class Viewer extends EventDispatcher<{ fetchCompleted: FetchInfo }> {
    // canvasElement: HTMLCanvasElement;
    camera: PerspectiveCamera;
    renderer: WebGLRenderer;
    scene: Scene;
    controls: MapControls;

    private queryPolygon: null | number[][][] = null;

    renderTarget: WebGLRenderTarget;
    sceneOrtho: Scene;
    cameraOrtho: OrthographicCamera;

    edlMaterial: EDLMaterial;

    width: number;
    height: number;

    addedChunks: PointChunk[] = [];
    boundingMeshes: [LineSegments, SourceFileMetadata][] = [];

    loadCount = 0;
    onLoadCountUpdated: null | ((count: number) => void) = null;

    onCameraPositionChanged:
        | null
        | ((params: {
              offset: number[];
              camera: { position: number[]; compassAngleRad: number; rotate: boolean };
          }) => void) = null;

    cameraInitialized = false;

    lastFetchID = 0;
    lastFetchAbortController: AbortController | null = null;

    constructor() {
        super();

        this.renderer = new WebGLRenderer({
            alpha: true,
        });

        this.width = Math.max(1, this.renderer.domElement.clientWidth);
        this.height = Math.max(1, this.renderer.domElement.clientHeight);

        this.camera = new PerspectiveCamera(75, this.width / this.height, CAM_NEAR, CAM_FAR);
        this.scene = new Scene();

        // Use a Z-up coordinate system
        this.camera.up.set(0, 0, 1);

        this.renderer.setSize(this.width, this.height);

        const depthTexture = new DepthTexture(this.width, this.height, UnsignedIntType);

        this.renderTarget = new WebGLRenderTarget(this.width, this.height, { depthTexture: depthTexture });

        this.edlMaterial = new EDLMaterial(this.renderTarget.texture, depthTexture, CAM_NEAR, CAM_FAR);

        this.edlMaterial.setResolution(this.width, this.height);

        this.sceneOrtho = new Scene();
        this.sceneOrtho.add(new Mesh(new PlaneGeometry(2, 2), this.edlMaterial));
        this.cameraOrtho = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

        this.controls = new MapControls(this.camera, this.renderer.domElement);

        // Stop rotation on user interaction
        this.controls.addEventListener("end", () => {
            this.controls.autoRotate = false;
            this.positionChanged();
        });

        this.controls.addEventListener("change", () => {
            this.positionChanged();
        });

        this.resetCamera();
        this.controls.autoRotate = true;
        if ("zoomToCursor" in this.controls) {
            this.controls.zoomToCursor = true;
        }
        this.controls.minDistance = 5.0;
        this.controls.maxPolarAngle = Math.PI / 2;
    }

    renderRequested = false;
    requestRender() {
        if (!this.renderRequested) {
            this.renderRequested = true;

            // TODO: should only render when something changes
            requestAnimationFrame(() => {
                this.render();
            });
        }
    }

    positionChanged() {
        if (!this.cameraInitialized) {
            return;
        }

        const camPos = this.camera.position.toArray();
        this.onCameraPositionChanged?.({
            camera: {
                compassAngleRad: this.controls.getAzimuthalAngle(),
                position: [camPos[0], camPos[1], this.camera.position.z],
                rotate: this.controls.autoRotate,
            },
            offset: this.getCurrentOffset().toArray(),
        });
    }

    private render() {
        this.renderRequested = false;
        this.controls.update();

        // Render the points to a offscreen buffer
        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.scene, this.camera);

        // Render the the buffer to the screen (using EDL)
        this.renderer.setRenderTarget(null);
        this.renderer.render(this.sceneOrtho, this.cameraOrtho);

        this.requestRender();
    }

    clearContent() {
        for (const p of this.addedChunks) {
            this.scene.remove(p.points);
            p.points.geometry.dispose();
        }
    }

    debounceFetchTimer: number = 0;

    fetchPoints({
        lumidb,
        classificationMask: classFilter,
        metaFilter,
        pointLimit,
        maxDensity,
        queryPolygon,
        why,
        tableName,
    }: FetchPointsOptions) {
        if (metaFilter === null) {
            console.log(`no filter, skipping fetch (${why})`);
            return;
        }
        if (this.debounceFetchTimer) {
            clearTimeout(this.debounceFetchTimer);
            this.debounceFetchTimer = 0;
        }
        this.debounceFetchTimer = setTimeout(async () => {
            const fetchID = performance.now();
            if (this.lastFetchAbortController) {
                console.warn("cancel previous query", this.lastFetchID);
                this.lastFetchAbortController.abort();
            }

            const classFilterArray = [];
            for (let i = 0; i < 32; i++) {
                if (classFilter & (1 << i)) {
                    classFilterArray.push(i);
                }
            }

            const abortController = new AbortController();
            console.log("fetchPoints", why, queryPolygon, pointLimit);
            const start = performance.now();
            this.loadCount++;
            this.onLoadCountUpdated?.(this.loadCount);
            try {
                this.lastFetchID = fetchID;
                this.lastFetchAbortController = abortController;
                const apiPoints = await PointChunk.loadFromAPI(
                    lumidb,
                    tableName,
                    queryPolygon,
                    pointLimit,
                    maxDensity,
                    metaFilter,
                    classFilterArray,
                    abortController.signal,
                );

                if (this.lastFetchAbortController === abortController) {
                    this.lastFetchAbortController = null;
                }

                if (fetchID === this.lastFetchID) {
                    this.dispatchEvent({
                        type: "fetchCompleted",
                        pointCount: apiPoints.pointCount,
                        elapsed: performance.now() - start,
                        queryPolygon: queryPolygon,
                        queryArea: apiPoints.queryArea,
                        byteSize: apiPoints.byteSize,
                        seenIndices: Array.from(apiPoints.seenIndices),
                    });

                    this.clearContent();
                    this.addedChunks.push(apiPoints);
                    this.scene.add(apiPoints.points);
                    this.queryPolygon = queryPolygon;
                    this.updateBoundsCenter();
                } else {
                    console.warn("fetch result is too old, discarding the response", fetchID, this.lastFetchID);
                    return;
                }
            } finally {
                this.loadCount--;
                this.onLoadCountUpdated?.(this.loadCount);
            }
        }, 300);
        return this.debounceFetchTimer;
    }

    setSizeDebounceHandle = 0;
    setSizeDebounced(width: number, height: number) {
        if (this.setSizeDebounceHandle) {
            clearTimeout(this.setSizeDebounceHandle);
            this.setSizeDebounceHandle = 0;
        }
        this.setSizeDebounceHandle = setTimeout(() => {
            this.setSize(width, height);
        }, 200);
    }

    setSize(width: number, height: number) {
        console.log("setSize", width, height);
        this.width = width;
        this.height = height;

        this.edlMaterial.setResolution(width, height);

        this.renderer.setSize(width, height);
        this.renderTarget.setSize(width, height);

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }

    setColorMode(colorMode: ColorMode) {
        DEFAULT_POINT_MATERIAL.setColorMode(colorMode);
    }

    setClassificationMask(mask: number) {
        DEFAULT_POINT_MATERIAL.setClassificationMask(mask);
    }

    resetCamera() {
        this.cameraInitialized = true;
        this.controls.reset();
        this.camera.lookAt(new Vector3(0, 0, 0));
        this.camera.position.set(0, -400, 300);
        this.positionChanged();
    }

    setCamera(position: number[], rotate: boolean) {
        this.camera.position.fromArray(position);
        this.camera.lookAt(new Vector3(0, 0, 0));
        this.controls.autoRotate = rotate;
    }

    toggleAnimation() {
        this.controls.autoRotate = !this.controls.autoRotate;
    }

    adjustPointSize(delta: number) {
        DEFAULT_POINT_MATERIAL.adjustPointSize(delta);
    }

    updateBoundsCenter() {
        const center = this.getCurrentOffset();

        for (const [mesh, fileInfo] of this.boundingMeshes) {
            mesh.position.set(
                -center.x + (fileInfo.transformed_aabb.min[0] + fileInfo.transformed_aabb.max[0]) / 2,
                -center.y + (fileInfo.transformed_aabb.min[1] + fileInfo.transformed_aabb.max[1]) / 2,
                0,
            );
        }
    }

    addBoundingMeshes(meta: Map<number, SourceFileMetadata>) {
        for (const [_, file] of meta) {
            const box = new Box3(
                new Vector3(file.transformed_aabb.min[0], file.transformed_aabb.min[1], file.transformed_aabb.min[2]),
                new Vector3(file.transformed_aabb.max[0], file.transformed_aabb.max[1], file.transformed_aabb.max[2]),
            );

            const size = box.getSize(new Vector3());

            const boxGeom = new BoxGeometry(size.x, size.y, size.z);

            const edgesGeom = new EdgesGeometry(boxGeom);

            const bounds = new LineSegments(edgesGeom, new LineBasicMaterial({ color: "green", depthWrite: false }));

            bounds.visible = false;

            this.boundingMeshes.push([bounds, file]);
            this.scene.add(bounds);
        }
    }

    updateHighlightedBoundaries(file_paths: string[]) {
        for (const [bounds, fileInfo] of this.boundingMeshes) {
            if (file_paths.includes(fileInfo.filename)) {
                bounds.visible = true;
            } else {
                bounds.visible = false;
            }
        }
    }

    private getCurrentOffset() {
        if (!this.queryPolygon) {
            return new Vector2(0, 0);
        }

        let maxX = -Infinity;
        let maxY = -Infinity;
        let minX = Infinity;
        let minY = Infinity;

        for (const p of this.queryPolygon) {
            for (const [x, y] of p) {
                maxX = Math.max(maxX, x);
                maxY = Math.max(maxY, y);
                minX = Math.min(minX, x);
                minY = Math.min(minY, y);
            }
        }

        return new Vector2((maxX + minX) / 2, (maxY + minY) / 2);
    }
}
