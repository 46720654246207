import { LumiDB } from "@lumidb/lumidb";
import { BufferGeometry, Points, Vector3 } from "three";
import { CustomPointMaterial } from "./point-material";

export const DEFAULT_POINT_MATERIAL = new CustomPointMaterial();

export class PointChunk {
    points: Points;
    pointCount: number;
    byteSize: number;
    seenIndices: Set<number>;
    offset: Vector3;
    queryArea: number;

    constructor(
        points: Points,
        pointCount: number,
        byteSize: number,
        seenIndices: Set<number>,
        offset: Vector3,
        queryArea: number,
    ) {
        this.points = points;
        this.pointCount = pointCount;
        this.byteSize = byteSize;
        this.seenIndices = seenIndices;
        this.offset = offset;
        this.queryArea = queryArea;
    }

    static async startFileDownload(
        lumidb: LumiDB,
        tableName: string,
        queryPolygon: number[][][],
        maxPoints: number | null,
        maxDensity: number | null,
        sourceIdFilter: number[] | null,
        classificationMask: number,
    ) {
        const classFilterArray = [];
        for (let i = 0; i < 32; i++) {
            if (classificationMask & (1 << i)) {
                classFilterArray.push(i);
            }
        }
        const resp = await lumidb.query({
            tableName,
            maxPoints,
            maxDensity,
            sourceFileFilter: sourceIdFilter,
            queryBoundary: { Polygon: queryPolygon },
            queryCRS: "EPSG:3857",
            classFilter: classFilterArray,
            outputType: "laz",
        });

        // Currently the query_spatial uses POST, so the download can't be started with a simple link
        // TODO: investigate if this starting the download this way has some performance impact
        const blob = await resp.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = resp.headers.get("Content-Disposition")?.split("filename=")[1].replaceAll('"', "") || "export.laz";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    static async loadFromAPI(
        lumidb: LumiDB,
        tableName: string,
        queryPolygon: number[][][],
        maxPoints: number | null,
        maxDensity: number | null,
        sourceFileFilter: number[] | null,
        class_filter: number[] | null,
        abortSignal?: AbortSignal,
    ) {
        const start = performance.now();

        const resp = await lumidb.query({
            tableName,
            maxPoints,
            maxDensity,
            sourceFileFilter: sourceFileFilter,
            queryBoundary: { Polygon: queryPolygon },
            queryCRS: "EPSG:3857",
            classFilter: class_filter,
            outputType: "threejs",
            abortSignal,
        });

        console.log(
            "fetch complete, time:",
            performance.now() - start,
            "ms, pointCount:",
            resp.pointCount,
            "offset:",
            resp.offset,
        );

        const points = new Points(resp.pointGeometry as unknown as BufferGeometry, DEFAULT_POINT_MATERIAL);

        return new PointChunk(
            points,
            resp.pointCount,
            resp.bytes,
            new Set(resp.source_file_ids),
            new Vector3().fromArray(resp.offset),
            resp.queryArea,
        );
    }
}
