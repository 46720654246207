import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { Button } from "./ui/button";

export function ApiKeyInput(props: { onSetKey: (key: string) => void }) {
    const [keyInput, setKeyInput] = useState("");
    const [invalidKey, setInvalidKey] = useState(false);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e?.preventDefault();

        if (keyInput.length === 0) {
            setInvalidKey(true);
        } else {
            setInvalidKey(false);
            props.onSetKey(keyInput);
        }
    }

    return (
        <Dialog modal={true} open={true} defaultOpen={true}>
            <DialogContent hideCloseButton={true}>
                <DialogHeader>
                    <DialogTitle>API key</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="flex gap-2">
                    <Input
                        type="text"
                        value={keyInput}
                        onChange={(e) => setKeyInput(e.target.value)}
                        placeholder="Insert your LumiDB API key"
                        className={invalidKey ? "border-red-500" : ""}
                    />
                    <Button type="submit">Set</Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}
